import { List, fromJS } from 'immutable';
import AlgoliaIndex from '../utils/algoliaIndex';

export const GET_QUESTION = 'GET_QUESTION';

const buildCategoryFilters = (categories) => {
  const prefixedCategories = ['Home', ...categories];
  const filters = {

  };
  /*

  lvl0: "Home"
  lvl1: "Home > LiveChat Pro"
  lvl2: "Home > LiveChat Pro > Algemeen"

  */
  prefixedCategories.forEach((value, index) => {
    let joinedCategories = [];
    for (let i = 0; i < index; i += 1) {
      joinedCategories.push(prefixedCategories[i]);
    }

    joinedCategories = [...joinedCategories, value];

    filters[`hierarchicalCategories.lvl${index}`] = joinedCategories.join(' > ');
  });

  return Object.keys(filters).map(key => `${key}:"${filters[key]}"`).join(' AND ');
};

export const fetchQuestion = ({
  questionName,
  categories,
}) => async (dispatch) => {
  const quesionSearch = await AlgoliaIndex.search({
    query: questionName,
    filters: buildCategoryFilters(categories),
  });

  const translationKey = quesionSearch.hits.find(q => q.question.indexOf(questionName) !== -1).translation_key;

  const quesionsSearch = await AlgoliaIndex.search({
    query: translationKey,
    facets: 'language',
  });

  const questions = fromJS(quesionsSearch.hits);

  dispatch({
    type: GET_QUESTION,
    payload: {
      questions,
    },
  });
};

export default function (state = List(), action) {
  switch (action.type) {
    case GET_QUESTION:
      return action.payload.questions;
    default:
      return state;
  }
}
