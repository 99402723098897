import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { encodeURL } from '../helpers/encodeURL';

const Breadcrumbs = ({ hierarchicalCategories }) => {
  const path = hierarchicalCategories.toList().last().split(' > ');

  return (
    <Breadcrumb style={{
      marginTop: '10px',
    }}
    >
      <BreadcrumbItem><Link to="/">{path[0]}</Link></BreadcrumbItem>
      <BreadcrumbItem><Link to={encodeURL(`/category/${path[1]}`)}>{path[1]}</Link></BreadcrumbItem>
      {path[2] && <BreadcrumbItem><Link to={encodeURL(`/category/${path[1]}/${path[2]}`)}>{path[2]}</Link></BreadcrumbItem>}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  hierarchicalCategories: ImmutablePropTypes.map.isRequired,
};

export default Breadcrumbs;
