import React, { Fragment } from 'react';

import SearchConfigure from '../../composables/SearchConfigure/SearchConfigure';

import SearchHits from '../../components/SearchHits';

const Search = () => (
  <Fragment>
    <SearchConfigure />
    <SearchHits />
  </Fragment>
);

export default Search;
