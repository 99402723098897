
import { createSelector } from 'reselect';
import { List } from 'immutable';

const getTopQuestions = (state, category) => state.getIn(['topQuestions', category]);

export default createSelector(
  [
    getTopQuestions,
  ],
  topQuestions => topQuestions || List(),
);
