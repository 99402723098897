/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import { Map, List } from 'immutable';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux-immutable';

import categoriesReducer from '../ducks/categories';
import categoryReducer from '../ducks/category';
import languageReducer from '../ducks/language';
import activeCategoryReducer from '../ducks/activeCategory';
import topQuestionsReducer from '../ducks/topQuestions';
import questionReducer from '../ducks/question';
import clientHistoryReducer from '../ducks/clientHistory';
import localStorageMiddleware from '../middleware/localStorageMiddleware';

export const initialState = Map({
  categories: List(),
  category: List(),
  question: List(),
  language: {
    code: 'nl',
    label: 'Nederlands',
    icon: 'nl',
  },
  activeCategory: '',
  topQuestions: Map(),
  clientHistory: List(),
});

const getInitialState = () => {
  if (localStorage.getItem('language')) {
    return initialState.set('language', JSON.parse(localStorage.getItem('language')));
  }

  return initialState;
};

const rootReducer = combineReducers({
  categories: categoriesReducer,
  category: categoryReducer,
  language: languageReducer,
  activeCategory: activeCategoryReducer,
  question: questionReducer,
  topQuestions: topQuestionsReducer,
  clientHistory: clientHistoryReducer,
});

const middleware = applyMiddleware(thunk, localStorageMiddleware);

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

export default createStore(rootReducer, getInitialState(), composeEnhancers(
  middleware,
));
