import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 50px;
  background-color: #efefef;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;

  & > * {
    margin: 0 5px;
  }
`;

export const InputWrapper = styled.div`
  width: 80%;
`;


export const SearchIcon = styled(FontAwesomeIcon).attrs({
  icon: 'search',
})`
  position: relative;
  top: 50px;
  left: 31px;
  font-size: 30px;
  opacity: 0.4;
`;
