import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ClipboardJS from 'clipboard';
import {
  Button, UncontrolledTooltip, /* UncontrolledPopover, PopoverBody, */
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LANGUAGES from '../../constants/languages';
// import { SECOND_PER_CHAR } from '../../constants';

import { fetchQuestion } from '../../ducks/question';
import { fetchCategory } from '../../ducks/category';

import { decodeURL } from '../../helpers/encodeURL';

import Breadcrumbs from '../../components/Breadcrumbs';
import FlagIcon from '../../components/FlagIcon';

import i18n from '../../i18n';

import {
  QuestionHeader,
  QuestionHeading,
  AvaiableLanguagesWrapper,
  // SecondaryText,
  TagsWrapper,
  Badge,
  CopyButtonWrapper,
  AnswerWrapper,
} from './Question.styled';

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

const mapStateToProps = state => ({
  questions: state.get('question'),
  language: state.get('language'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchQuestion,
  fetchCategory,
}, dispatch);


class Question extends Component {
  constructor(props) {
    super(props);

    const { language } = this.props;

    this.state = {
      language: language.code,
      triggeredLanguageChange: false,
    };
  }

  componentDidMount() {
    const {
      fetchQuestion: fetchQuestionAction,
      match,
      language,
    } = this.props;
    const questionName = decodeURL(match.params[1]);
    const categories = match.params[0].split('/');

    fetchQuestionAction({
      questionName,
      categories,
    });

    const clipboard = new ClipboardJS('#copyToClipboard');

    const codeClip = new ClipboardJS('#answer code', {
      text(trigger) {
        return trigger.innerText;
      },
    });

    clipboard.on('success', (e) => {
      e.clearSelection();
    });
    codeClip.on('success', (e) => {
      e.clearSelection();
      e.trigger.parentNode.querySelector('span.tooltip').innerText = i18n.toolTip.copied[language.code];
    });

    this.applyToolTip();
  }

  getTooltip = () => {
    const { language } = this.props;
    const tooltip = document.createElement('span');
    tooltip.className = 'tooltip';
    tooltip.innerText = i18n.toolTip.copyToClipboard[language.code];

    return tooltip;
  }

  applyToolTip = () => {
    const { language } = this.props;
    const toolTip = this.getTooltip();
    let applied = false;

    setInterval(() => {
      if (!applied) {
        if (document.querySelectorAll('code').length !== 0) {
          document.querySelectorAll('code').forEach((element) => {
            // eslint-disable-next-line no-param-reassign
            element.onmouseover = (e) => {
              e.target.parentNode.insertBefore(toolTip, e.target.nextSibling);
            };

            // eslint-disable-next-line no-param-reassign
            element.onmouseleave = (e) => {
              e.target.parentNode.querySelector('span.tooltip').innerText = i18n.toolTip.copyToClipboard[language.code];
              e.target.parentNode.querySelector('span').remove();
            };
          });
          applied = true;
        }
      }
    }, 100);
  }

  changeLanguage = (language) => {
    this.setState({
      language,
      triggeredLanguageChange: true,
    });
    this.applyToolTip();
  }

  render() {
    const { questions, language: originalLanguage, match } = this.props;
    const { language, triggeredLanguageChange } = this.state;
    const questionName = decodeURL(match.params[1]);

    if (questions.size) {
      let requestedQuestion = questions.find(q => q.get('question').indexOf(questionName) !== -1);
      let originalQuestion = questions.find(q => q.get('language') === originalLanguage.code);
      const avaiableLanguages = questions.map(q => q.get('language')).sort();

      if (requestedQuestion === undefined) {
        return null;
      }

      if (triggeredLanguageChange) {
        requestedQuestion = questions.find(q => q.get('language') === language);
      }

      if (originalQuestion === undefined) {
        originalQuestion = requestedQuestion;
      }

      requestedQuestion = requestedQuestion || originalQuestion || questions.first();

      const answer = requestedQuestion.get('answer');

      /*  const wordsAmount = answer.split(' ').length;
      const charactersAmount = answer.split('').length;
      const [minutesToRead, secondsToRead] = ((charactersAmount * SECOND_PER_CHAR) / 60).toFixed(2).split('.'); */

      return (
        <Grid>
          <Row>
            <Col md={12}>
              <QuestionHeader>
                <QuestionHeading>
                  <AvaiableLanguagesWrapper>
                    {avaiableLanguages.map((algoliaLanguage) => {
                      const foundLanguage = LANGUAGES.find(l => l.code === algoliaLanguage);

                      return (
                        <FlagIcon
                          isInline
                          active={requestedQuestion.get('language') === algoliaLanguage}
                          key={algoliaLanguage}
                          icon={foundLanguage.icon}
                          onClick={() => this.changeLanguage(algoliaLanguage)}
                        />
                      );
                    })}
                  </AvaiableLanguagesWrapper>
                  <h2>{requestedQuestion.get('question')}</h2>
                </QuestionHeading>
                {/*  <div>
                  <Button id="UncontrolledPopover" size="sm" type="button" outline>Analytics</Button>
                  <UncontrolledPopover placement="bottom" target="UncontrolledPopover">
                    <PopoverBody>
                      <SecondaryText><FontAwesomeIcon icon="eye" /> {requestedQuestion.get('hits_count')} views</SecondaryText>
                      <SecondaryText><FontAwesomeIcon icon="smile" /> {requestedQuestion.get('likes')} like</SecondaryText>
                      <SecondaryText><FontAwesomeIcon icon="frown" /> {requestedQuestion.get('dislikes')} dislike</SecondaryText>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div> */}
              </QuestionHeader>
              <Breadcrumbs hierarchicalCategories={originalQuestion.get('hierarchicalCategories')} />
              <TagsWrapper>
                {requestedQuestion.get('tags').map(tag => (
                  <Badge color="secondary" key={tag}>{tag}</Badge>
                ))}
              </TagsWrapper>
              {/*  <SecondaryText>
                {wordsAmount} woorden, {charactersAmount} karakters, {minutesToRead} min {secondsToRead} sec leestijd
              </SecondaryText> */}
              <CopyButtonWrapper>
                <Button color="primary" data-clipboard-target="#answer" id="copyToClipboard">
                  <FontAwesomeIcon icon="copy" />
                </Button>
              </CopyButtonWrapper>
              <UncontrolledTooltip placement="top" target="copyToClipboard">
                Copy to clipboard
              </UncontrolledTooltip>
              <AnswerWrapper id="answer">
                {htmlToReactParser.parse(answer)}
              </AnswerWrapper>
            </Col>
          </Row>
        </Grid>
      );
    }

    return null;
  }
}

Question.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  questions: ImmutablePropTypes.list.isRequired,
  fetchQuestion: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Question);
