import { createSelector } from 'reselect';
import { Map } from 'immutable';

const getCategory = state => state.get('category');
const getCategoryName = (state, categoryName) => categoryName;

export default createSelector(
  [
    getCategory,
    getCategoryName,
  ],
  (categoryData, categoryName) => {
    if (categoryData.size > 0) {
      const groupedCategories = categoryData.groupBy(category => category.get('categories').first());

      const otherCategories = categoryData.groupBy(category => category.get('categories').first())
        .keySeq()
        .toList()
        .filter(category => category !== categoryName);

      return Map({
        category: groupedCategories.get(categoryName, Map()),
        otherCategories,
      });
    }

    return Map({
      category: [],
      otherCategories: [],
    });
  },
);
