import styled from 'styled-components';

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * {
    flex: 0 calc(50% - 5px);
  }
`;
