import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

const highlightFound = (limit, wordCount, found, index) => {
  const { isHighlighted, value } = found;

  let escapedValue = value;

  if (value) {
    escapedValue = value.replace(/<(?:.|\n)*?>/gm, ' ');
    escapedValue = escapedValue.replace(/&nbsp;/gm, ' ');
  }

  if (limit) {
    if (wordCount + escapedValue.length > limit) {
      escapedValue = `${escapedValue.substring(0, limit)}...`;
    }
  }

  if (isHighlighted) {
    return (
      <b key={index}><u>{escapedValue}</u></b>
    );
  }

  return (
    <span key={index}>{escapedValue}</span>
  );
};

const Highlight = ({
  highlight, attribute, hit, limit, withLineBreak, WrapComponent, MasterWrapComponent,
}) => {
  let wordCount = 0;

  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  const highlighted = parsedHit.map(
    (found, index) => {
      if (limit === null) {
        if (Array.isArray(found)) {
          return (
            <WrapComponent key={index}>
              {found.map((subFound, subIndex) => highlightFound(limit, wordCount, subFound, subIndex))}
            </WrapComponent>
          );
        }

        return highlightFound(limit, wordCount, found, index);
      }

      if (wordCount < limit) {
        if (Array.isArray(found)) {
          return found.map((subFound, subIndex) => {
            const highlightedBlock = (
              <WrapComponent key={subIndex}>
                {highlightFound(limit, wordCount, subFound, subIndex)}
              </WrapComponent>
            );
            wordCount += subFound.value.length;

            return highlightedBlock;
          });
        }

        const highlightedBlock = highlightFound(limit, wordCount, found, index);
        wordCount += found.value.length;
        return highlightedBlock;
      }

      return null;
    },
  );

  const lineBreak = withLineBreak ? <br /> : null;

  return highlighted.length > 0 ? (
    <MasterWrapComponent>
      {highlighted} {lineBreak}
    </MasterWrapComponent>
  ) : lineBreak;
};

Highlight.propTypes = {
  highlight: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
  limit: PropTypes.number,
  withLineBreak: PropTypes.bool,
  WrapComponent: PropTypes.any,
  MasterWrapComponent: PropTypes.any,
};

Highlight.defaultProps = {
  limit: null,
  withLineBreak: false,
  WrapComponent: ({ children }) => (<span>{children}</span>),
  MasterWrapComponent: ({ children }) => (<span>{children}</span>),
};

export default connectHighlight(Highlight);
