export default [
  {
    code: 'en',
    label: 'Engels',
    icon: 'gb',
  },
  {
    code: 'de',
    label: 'Duits',
    icon: 'de',
  },
  {
    code: 'fr',
    label: 'Frans',
    icon: 'fr',
  },
  {
    code: 'nl',
    label: 'Nederlands',
    icon: 'nl',
  },
  {
    code: 'es',
    label: 'Spaans',
    icon: 'es',
  },
  {
    code: 'it',
    label: 'Italiaans',
    icon: 'it',
  },
  {
    code: 'pt',
    label: 'Portuguese',
    icon: 'pt',
  }
];
