import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { fetchCategory } from '../../ducks/category';
import getCategory from '../../selectors/getCategory';

import categoryParser from '../../helpers/categoryParser';
import { encodeURL } from '../../helpers/encodeURL';

import TopQuestions from '../../composables/TopQuestions/TopQuestions';
import Link, { ActiveLink } from '../../components/Link';

import CategoryWrapper, { CategoryList } from './Category.styled';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { category } = categoryParser(match.params[0]);

  return {
    category: getCategory(state, category),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCategory,
}, dispatch);

class Category extends Component {
  componentDidMount() {
    const { fetchCategory: fetchCategoryAction, match } = this.props;
    const { masterCategory } = categoryParser(match.params[0]);

    fetchCategoryAction(masterCategory);
  }

  render() {
    const { category, match } = this.props;
    const { category: categoryName, masterCategory } = categoryParser(match.params[0]);

    const isFirstLevel = categoryName === masterCategory;

    return (
      <Grid fluid>
        <Row>
          <Col md={9}>
            <CategoryWrapper>
              <ActiveLink to={isFirstLevel ? '/' : encodeURL(`/category/${masterCategory}`)}>
                <FontAwesomeIcon icon="folder-open" /> {categoryName}
              </ActiveLink>
              <CategoryList>
                {isFirstLevel && category.get('otherCategories').size ? category.get('otherCategories').map(entry => (
                  <Link to={encodeURL(`${categoryName}/${entry}`)} key={entry}>
                    <FontAwesomeIcon icon="folder" /> {entry}
                  </Link>
                )) : null}
                {category.get('category').size ? category.get('category').map(entry => (
                  <Link to={encodeURL(`/${isFirstLevel ? categoryName : `${masterCategory}/${categoryName}`}/q/${entry.get('question')}`)} key={entry.get('objectID')}>
                    <FontAwesomeIcon icon="question-circle" /> {entry.get('question')}
                  </Link>
                )) : null}
              </CategoryList>
            </CategoryWrapper>
          </Col>
          <Col md={3}>
            <TopQuestions category={masterCategory} />
          </Col>
        </Row>
      </Grid>

    );
  }
}

Category.propTypes = {
  category: ImmutablePropTypes.map.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Category);
