import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { InstantSearch } from 'react-instantsearch-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'flag-icon-css/css/flag-icon.min.css';

// eslint-disable-next-line no-unused-vars
import fontAwesome from './utils/fontAwesome';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import store from './store/store';
import { searchClient } from './utils/algoliaIndex';

import {
  INDEX_NAME,
} from './constants';

import Home from './pages/Home/Home';
import Category from './pages/Category/Category';
import Question from './pages/Question/Question';
import Search from './pages/Search/Search';
// import { useAuth0 } from "@auth0/auth0-react";
import Header from './composables/Header/Header';
import HistoryLogger from './composables/HistoryLogger/HistoryLogger';
import NewPage from './pages/NewIndex/NewPage';
import Auth from './pages/NewIndex/Auth';
import PrivateRoute from './pages/NewIndex/Useauth';
import Profile from './pages/NewIndex/Profile';


ReactDOM.render(
  <Auth0Provider
  domain="myanswersv2.eu.auth0.com"
  clientId="pWNyFmWOwiSaeRB0LoLTtwIjVtLovqjZ"
  redirectUri={`${window.location.origin}/#/profile`}
  audience="https://myanswersv2.eu.auth0.com/api/v2/"
  scope="read:current_user"
>

  <Provider store={store}>
    <InstantSearch indexName={INDEX_NAME} searchClient={searchClient}>
      <Router>
        <HistoryLogger />
        <Header />
        <div style={{ padding: '40px' }}>
          <PrivateRoute path="/" exact component={NewPage}/>
          <PrivateRoute path="/category/(.*)" exact component={Category} />
          <PrivateRoute path="/(.*)/q/(.*)" exact component={Question} />
          <PrivateRoute path="/search" component={Search} />
          <PrivateRoute path="/newCategory" component={NewPage} />
          <PrivateRoute path="/profile" component={Profile} />
          <Route path="/auth" component={Auth} />
        </div>
      </Router>
    </InstantSearch>
  </Provider>
  </Auth0Provider>,
  document.getElementById('root'),
);