import { List } from 'immutable';

const PUSH_CLIENT_HISTORY = 'PUSH_CLIENT_HISTORY';

export const pushClientHistory = history => ({
  type: PUSH_CLIENT_HISTORY,
  payload: {
    history,
  },
});

export default function (state = List(), action) {
  switch (action.type) {
    case PUSH_CLIENT_HISTORY:
      return state.push(action.payload.history);
    default:
      return state;
  }
}
