import React from 'react';
import PropTypes from 'prop-types';
import { connectHits } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TagsWrapper, Badge } from '../pages/Question/Question.styled';

import SearchHighlight from './SearchHighlight';

const HitWrapper = styled.div`
  padding: 0 10px;
  margin-top: 25px;
`;

const LinkHighlightWrapper = styled.b`
  text-decoration: underline;
`;

const Hits = ({ hits }) => (
  <div>
    {hits.map((hit) => {
      const categoryBreadcrumbs = Object.keys(hit.hierarchicalCategories)
        .map(key => hit.hierarchicalCategories[key])
        .pop();

      const categoryLink = categoryBreadcrumbs.split(' > ').filter(category => category !== 'Home')
        .join('/');

      return (
        <HitWrapper key={hit.objectID}>
          <Link
            to={`/${categoryLink}/q/${hit.question}`}
            style={{
              fontSize: '20px',
            }}
          >
            <SearchHighlight attribute="question" hit={hit} WrapComponent={LinkHighlightWrapper} />
          </Link> in {categoryBreadcrumbs}
          <SearchHighlight attribute="tags" hit={hit} withLineBreak MasterWrapComponent={TagsWrapper} WrapComponent={Badge} />
          <SearchHighlight attribute="answer" hit={hit} limit={500} />
        </HitWrapper>
      );
    })}
  </div>
);

Hits.propTypes = {
  hits: PropTypes.array.isRequired,
};

export default connectHits(Hits);
