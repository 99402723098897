import styled from 'styled-components';
import { Badge as BootstrapBadge } from 'reactstrap';

export const QuestionHeading = styled.div`
 & > * {
   display: inline
 }
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvaiableLanguagesWrapper = styled.div`
  margin-top: 7px;
`;

export const SecondaryText = styled.div`
  color: rgba(33, 33, 33, 0.5);
  font-size: 12px;
`;

export const TagsWrapper = styled.div`
  & > * {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Badge = styled(BootstrapBadge)`
  background: #dddddd;
  color: #000;
  font-weight: normal;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 4px;
`;

export const CopyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AnswerWrapper = styled.div`
 & * {
  color: #000 !important;
 }

 & code {
  color: black !important;
  font-family: inherit !important;
  font-size: inherit;
  background-color: #eeeef1 !important;
  cursor: pointer;
  padding: .2rem .4rem;
  border-radius: .25rem;
 }
`;
