import React from 'react';
import PropTypes from 'prop-types';
import { Configure } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

const mapStateToProps = state => ({
  languageCode: state.get('language').code,
  activeCategory: state.get('activeCategory'),
  clientHistory: state.get('clientHistory'),
});

export const props = ({
  activeCategory,
  languageCode,
  clientHistory,
}) => {
  const previousPage = clientHistory.get(-2, '');
  let filters = '';

  const IS_CATEGORY = activeCategory !== '';
  const IS_HOME = previousPage === '/';
  const IS_QUESTION = previousPage.indexOf('/q/') !== -1;

  if (IS_CATEGORY && (!IS_HOME && !IS_QUESTION)) {
    filters = `hierarchicalCategories.lvl1:"Home > ${activeCategory}" AND language:"${languageCode}" `;
  }

  return {
    filters,
  };
};

const SearchConfigure = ({ filters }) => (
  <Configure
    filters={filters}
    hitsPerPage={10}
  />
);

SearchConfigure.propTypes = {
  filters: PropTypes.string.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withProps(props),
)(SearchConfigure);
