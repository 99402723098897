import styled from 'styled-components';

export const TopQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h5`
  color: #4aa3df;
`;
