import { List, fromJS } from 'immutable';
import AlgoliaIndex from '../utils/algoliaIndex';

export const GET_CATEGORIES = 'GET_CATEGORIES';

export const fetchCategories = () => async (dispatch, getState) => {
  const language = getState().get('language');

  const categoriesRequest = await AlgoliaIndex.search('', {
    facets: ',hierarchicalCategories.lvl1, language',
    filters: `language:"${language.code}"`,
  });

  const categories = fromJS(Object.keys(categoriesRequest.facets['hierarchicalCategories.lvl1']).map(facet => facet.split('> ')[1]).sort());

  dispatch({
    type: GET_CATEGORIES,
    payload: {
      categories,
    },
  });
};

export default function (state = List(), action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return action.payload.categories;
    default:
      return state;
  }
}
