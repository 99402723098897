import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Row, Col } from 'react-flexbox-grid';

import { fetchCategories } from '../../ducks/categories';

import getCategories from '../../selectors/getCategories';

import Link from '../../components/Link';
import TopQuestions from '../../composables/TopQuestions/TopQuestions';

import { CategoriesWrapper } from './Home.styled';
import { useAuth0 } from "@auth0/auth0-react";

const mapStateToProps = state => ({
  categories: getCategories(state),
  state,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCategories,
}, dispatch);

class Home extends Component {
  componentDidMount() {
    const { fetchCategories: fetchCategoriesAction } = this.props;

    fetchCategoriesAction();
    var getData =JSON.parse(localStorage.getItem('user_metadata'));
    if (getData === null) {
      this.props.history.push('profile')
    }else{
      this.props.history.push('newCategory')
    }
    
  }

  render() {
    const { categories } = this.props;

    return (
      <Grid fluid>
        <Row>
          <Col md={9}>
            <CategoriesWrapper>
              {categories.size > 0 ? categories.map(category => (
                <Link to={`category/${category}`} key={category}>
                  <FontAwesomeIcon icon="folder" /> {category}
                </Link>
              )) : null}
            </CategoriesWrapper>
          </Col>
          <Col md={3}>
            <TopQuestions category="Home" />
          </Col>
        </Row>
      </Grid>
    );
  }
}

Home.propTypes = {
  categories: ImmutablePropTypes.list.isRequired,
  fetchCategories: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
