import styled from 'styled-components';
import { Input as BootstrapInput } from 'reactstrap';

export const Input = styled(BootstrapInput)`
  font-size: 20px;
  height: auto;
  padding: 15px;
  border-radius: 50px;
  padding-left: 80px;
`;
