import React, { Component,useState,useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CategoriesWrapper } from './../Home/Home.styled';
import { useLocation } from 'react-router-dom';
import { set } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../../components/Link';
import TopQuestions from '../../composables/TopQuestions/TopQuestions';
var filters = '';
// categories_livechatpro
//user_groups_livechatpro 7344559002 Cardoen,nebeus:136796255001
export default function NewPage() {
  const algoliaAppId = 'I9WKUNVPGV';
  const algoliaApiKey = '70487cfe216f7617c893ed5518107e2e';
  const algoliaApiCategIndex = "user_groups_livechatpro";
  const algoliaApiCategIndex2 = "categories_livechatpro";
  const client = algoliasearch(algoliaAppId, algoliaApiKey);
  const index = client.initIndex(algoliaApiCategIndex);
  const client2 = algoliasearch(algoliaAppId, algoliaApiKey);
  const index2 = client2.initIndex(algoliaApiCategIndex2);
const [categories, setcategories] = useState([]);
const [filter, setfilter] = useState('');
const [cat, setCat] = useState({is_hidden: 0,
  name_de: "Home",
  name_en: "Home",
  name_es: "Home",
  name_fr: "Home",
  name_it: "Home",
  name_nl: "Home",
  objectID: "144069115002",
  parent_object_id: null});

  const  titleCase =(str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
 }
useEffect(async() => {
  var getData =JSON.parse(localStorage.getItem('user_metadata'));
  if (getData === null) {
    this.props.history.push('profile');
  }
  var userMeata = JSON.parse(localStorage.getItem('user_metadata'));
    // const grp =  await index.search();  
    userMeata.groups.map((g) =>{
      filters+= ` name_en:"${titleCase(g)}" OR`;
      setfilter(filters)
    });
    filters = filters.substring(0, filters.length - 3);
    getDirectory(cat)
}, [])
useEffect(()=>{
  getDirectory(cat)
},[cat])

const getDirectory = async(data) =>{
  setCat(data);
  if (cat.name_en !== "Home") {
    filters=`parent_object_id:${cat.objectID}`
  }
  
  console.log("filtersfiltersfiltersfiltersfilters",filters,cat);
   const res = await index2.search('', {
    hitsPerPage: 50,
    filters: `is_hidden:0 AND ${filters}`
    });
    setcategories(res.hits)
}
    return (
        <Grid fluid>
        <Row>
          <Col md={9}>
            <CategoriesWrapper>
            {categories.length > 0 ? categories.map((category)=>{
      return (
        <Link onClick={() => getDirectory(category)} key={category.objectID}>
        <FontAwesomeIcon icon="folder" /> {category.name_en}
      </Link>
      );
    }):''}
            </CategoriesWrapper>
          </Col>
          <Col md={3}>
            <TopQuestions category="Home" />
          </Col>
        </Row>
        
      </Grid>
      
    )
}
