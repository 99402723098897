import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { encodeURL } from '../../helpers/encodeURL';

import Link from '../../components/Link';

import { fetchTopQuestions } from '../../ducks/topQuestions';
import getTopQuestions from '../../selectors/getTopQuestions';

import {
  TopQuestionsWrapper, Heading,
} from './TopQuestions.styled';
import i18n from '../../i18n';


const mapStateToProps = (state, { category }) => ({
  language: state.get('language'),
  topQuestions: getTopQuestions(state, category),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTopQuestions,
}, dispatch);

class TopQuestions extends Component {
  componentDidMount() {
    const { fetchTopQuestions: fetchTopQuestionsAction, category } = this.props;

    fetchTopQuestionsAction(category);
  }

  componentWillReceiveProps(nextProps) {
    const { fetchTopQuestions: fetchTopQuestionsAction, language, category } = this.props;

    if (nextProps.language.code !== language.code) {
      fetchTopQuestionsAction(category);
    }
  }

  render() {
    const { topQuestions, category, language } = this.props;

    return (
      <TopQuestionsWrapper>
        <Heading>{i18n.mostViewedFaqsWithin[language.code]} {category}:</Heading>
        {topQuestions.map((question) => {
          const categoryLink = question.get('hierarchicalCategories').last().split(' > ').filter(hierarchicalCategories => hierarchicalCategories !== 'Home')
            .join('/');

          return (
            <Link small="true" to={encodeURL(`/${categoryLink}/q/${question.get('question')}`)} key={question.get('objectID')}>
              <FontAwesomeIcon icon="question-circle" /> {question.get('question')}
            </Link>
          );
        })}
      </TopQuestionsWrapper>
    );
  }
}

TopQuestions.propTypes = {
  topQuestions: ImmutablePropTypes.list.isRequired,
  category: PropTypes.string.isRequired,
  fetchTopQuestions: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopQuestions);
