import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
} from 'reactstrap';

import LanguageDropdown from '../LanguageDropdown/LanguageDropdown';
import SearchInput from '../SearchInput/SearchInput';

import HeaderWrapper, {
  NavigationWrapper, InputWrapper, SearchIcon,
} from './Header.styled';

const Header = () => (
  <HeaderWrapper>
    <NavigationWrapper>
      <LanguageDropdown />
      <Link to="/"><Button color="primary">Home</Button></Link>
    </NavigationWrapper>
    <h3>Welcome to KB</h3>
    <InputWrapper>
      <SearchIcon />
      <SearchInput currentRefinement="Enerpac - DE Script" />
    </InputWrapper>
  </HeaderWrapper>
);

export default Header;
