import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
 var isAuthenticated1 = document.cookie.split(';');
var IsAuth = localStorage.getItem("userInfo");
console.log("isAuthenticated1",isAuthenticated1);
 var isLoggedIn;
  // if(isAuthenticated1 !== null){
    if(isAuthenticated1[1] == ' auth0.pWNyFmWOwiSaeRB0LoLTtwIjVtLovqjZ.is.authenticated=true'){
    isLoggedIn = true
  }
  else{
    isLoggedIn = false
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute