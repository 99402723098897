import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Auth = () => {
  const { user, isAuthenticated, isLoading,loginWithRedirect } = useAuth0();


  useEffect(() => {
    loginWithRedirect();
  }, [])

  return <h1></h1>
};

export default Auth;
