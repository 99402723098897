import { List, fromJS } from 'immutable';
import AlgoliaIndex from '../utils/algoliaIndex';

import { setActiveCategory } from './activeCategory';

export const GET_CATEGORY = 'GET_CATEGORY';

export const fetchCategory = category => async (dispatch, getState) => {
  const language = getState().get('language');

  const categoryRequest = await AlgoliaIndex.search({
    filters: `hierarchicalCategories.lvl1:"Home > ${category}" AND language:"${language.code}" AND status:"published"`,
    hitsPerPage: 1000,
  });

  // to do, test requests
  const requestN1 = await AlgoliaIndex.search({
    query: `name_${language.code}=Home ${category}`,
  });
  console.log('requestN1: ', requestN1);
  console.log('requestN1.hits: ', requestN1.hits);

  const requestN2 = await AlgoliaIndex.search({
    // to do, objectID = 1549934821, is set manually to 'Various' objectID
    query: `name_${language.code}="${category}" + parent_object_id=1549934821`,
  });
  console.log('requestN2: ', requestN2);
  console.log('requestN2.hits: ', requestN2.hits);

  const requestN3 = await AlgoliaIndex.search({
    // to do, objectID = 125069850, is set manually to 'question: "Cardoen - phrases utiles"' objectID
    query: `parent_object_id=125069850`,
  });
  console.log('requestN3: ', requestN3);
  console.log('requestN3.hits: ', requestN3.hits);
  // end of to do, test requests

  const mappedCategory = fromJS(categoryRequest.hits);

  dispatch(setActiveCategory(category));

  dispatch({
    type: GET_CATEGORY,
    payload: {
      mappedCategory,
    },
  });
};

export default function (state = List(), action) {
  switch (action.type) {
    case GET_CATEGORY:
      return action.payload.mappedCategory;
    default:
      return state;
  }
}
