export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';

export const setActiveCategory = category => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CATEGORY,
    payload: {
      category,
    },
  });
};

export default function (state = '', action) {
  switch (action.type) {
    case SET_ACTIVE_CATEGORY:
      return action.payload.category;
    default:
      return state;
  }
}
