import { Map, fromJS } from 'immutable';
import AlgoliaIndex from '../utils/algoliaIndex';

const GET_TOP_QUESTIONS = 'GET_TOP_QUESTIONS';

export const fetchTopQuestions = category => async (dispatch, getState) => {
  const language = getState().get('language');
  let filter = `hierarchicalCategories.lvl1:"Home > ${category}" AND language:"${language.code}"`;

  if (category === 'Home') {
    filter = `hierarchicalCategories.lvl0:"Home" AND language:"${language.code}"`;
  }

  const categoryRequest = await AlgoliaIndex.search({
    filters: filter,
    hitsPerPage: 10,
  });

  const mappedCategory = fromJS(categoryRequest.hits);

  dispatch({
    type: GET_TOP_QUESTIONS,
    payload: {
      category,
      mappedCategory,
    },
  });
};

export default function (state = Map(), action) {
  switch (action.type) {
    case GET_TOP_QUESTIONS:
      return state.set(action.payload.category, action.payload.mappedCategory);
    default:
      return state;
  }
}
