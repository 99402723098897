export default {
  searchKnowledgeBase: {
    en: 'Search knowledge base',
    de: 'Wissensdatenbank durchsuchen',
    fr: 'Rechercher dans la base de connaissances',
    nl: 'Zoek kennisbank',
    es: 'Base de conocimiento de búsqueda',
    it: 'Cerca nella knowledge base',
  },
  toolTip: {
    copyToClipboard: {
      en: 'Click to copy',
      de: 'Klicken Sie zum Kopieren',
      fr: 'Cliquez pour copier',
      nl: 'Klik om te kopiëren',
      es: 'Haga clic para copiar',
      it: 'Fare clic per copiare',
    },
    copied: {
      en: 'Copied to clipboard!',
      de: 'In die Zwischenablage kopiert!',
      fr: 'Copié dans le presse-papier!',
      nl: 'Gekopieerd naar klembord!',
      es: 'Copiado al portapapeles!',
      it: 'Copiato negli appunti!'
    },
  },
  language: {
    en: {
      en: 'English',
      de: 'German',
      fr: 'French',
      nl: 'Dutch',
      es: 'Spanish',
      it: 'Italian',
      pt: 'Portuguese',
    },
    de: {
      en: 'Englisch',
      de: 'Deutsche',
      fr: 'Französisch',
      nl: 'Niederländisch',
      es: 'Spanisch',
      it: 'Italienisch',
      pt: 'Portugiesisch',
    },
    fr: {
      en: 'Anglais',
      de: 'Allemand',
      fr: 'Français',
      nl: 'Néerlandais',
      es: 'Espagnol',
      it: 'Italien',
      pt: 'Portugais',
    },
    nl: {
      en: 'Engels',
      de: 'Duits',
      fr: 'Frans',
      nl: 'Nederlands',
      es: 'Spaans',
      it: 'Italiaans',
      pt: 'Portuguese',
    },
    es: {
      en: 'Inglés',
      de: 'Alemán',
      fr: 'Francés',
      nl: 'Holandés',
      es: 'Español',
      it: 'Italiano',
      pt: 'Portugués',
    },
    it: {
      en: 'Inglese',
      de: 'Tedesco',
      fr: 'Francese',
      nl: 'Olandese',
      es: 'Spagnolo',
      it: 'Italiano',
      pt: 'Portoghese',
    },
    pt: {
      en: 'Inglese',
      de: 'Tedesco',
      fr: 'Francese',
      nl: 'Olandese',
      es: 'Spagnolo',
      it: 'Italiano',
      pt: 'Portuguese',
    },
  },
  mostViewedFaqsWithin: {
    en: 'Most viewed FAQs within ',
    de: 'Meistgesehene FAQs',
    fr: 'FAQ les plus consultées',
    nl: 'Meest bekeken veelgestelde vragen',
    es: 'Preguntas más frecuentes dentro de',
    it: "Domande frequenti più viste all'interno",
  },
};
