import { createSelector } from 'reselect';
import { List } from 'immutable';

const getCategories = state => state.get('categories');

export default createSelector(
  [
    getCategories,
  ],
  (categories) => {
    if (categories.size > 0) {
      return categories;
    }

    return List();
  },
);
