import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default styled(Link)`
  font-size: ${props => (props.small ? '14px' : '22px')} ;
  color: ${props => (props.small ? 'blue' : '#212121')} ;
  margin: 5px 2.5px;
  padding: ${props => (props.small ? '5px' : '15px')} ;
  cursor: pointer;

  &:hover {
    color: ${props => (props.small ? 'blue' : '#212121')} ;
    background-color: ${props => (props.small ? 'rgba(0, 0, 0, 0.1)' : 'rgba(74, 163, 223, 0.6)')} ;
  }
`;

export const ActiveLink = styled(Link)`
  font-size: 22px;
  color: #212121;
  margin: 5px 2.5px;
  padding: 15px;
  background-color: #4aa3df;
  cursor: pointer;

  &:hover {
    color: #212121;
  }
`;
