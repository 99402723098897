import languages from '../constants/languages';

import { fetchCategory } from './category';
import { fetchCategories } from './categories';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage = code => async (dispatch, getState) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: {
      language: languages.find(language => language.code === code),
    },
  });

  dispatch(fetchCategory(getState().get('activeCategory')));
  dispatch(fetchCategories());
};

export default function (state = languages[0], action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload.language;
    default:
      return state;
  }
}
