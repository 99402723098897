import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { pushClientHistory } from '../../ducks/clientHistory';

const mapDispatchToProps = dispatch => bindActionCreators({
  pushClientHistory,
}, dispatch);

class HistoryLogger extends Component {
  componentDidMount() {
    const {
      pushClientHistory: pushClientHistoryAction, history,
    } = this.props;

    history.listen(({ pathname }) => {
      pushClientHistoryAction(pathname);
    });
  }


  componentWillReceiveProps() {
    const {
      location,
    } = this.props;

    if (location.pathname === '/search') {
      document.querySelector('input').value = '';
    }
  }


  render() {
    return null;
  }
}

HistoryLogger.propTypes = {
  pushClientHistory: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withRouter,
)(HistoryLogger);
