import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';

import languages from '../../constants/languages';

import { setLanguage } from '../../ducks/language';

import i18n from '../../i18n';

import FlagIcon from '../../components/FlagIcon';

const mapStateToProps = state => ({
  currentLanguage: state.get('language'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLanguage,
}, dispatch);

export const props = ({
  setLanguage: setLanguageAction,
  history,
}) => ({
  setLanguage: languageCode => () => {
    setLanguageAction(languageCode);

    if (history.location !== '/') {
      history.replace('/');
    }
  },
});

const LanguageDropdown = ({ currentLanguage, setLanguage: setLanguageAction }) => (
  <UncontrolledDropdown>
    <DropdownToggle caret color="primary">
      <FlagIcon icon={currentLanguage.icon} />
      {i18n.language[currentLanguage.code][currentLanguage.code]}
    </DropdownToggle>
    <DropdownMenu>
      {languages.filter(({ code }) => code !== currentLanguage.code).map(language => (
        <DropdownItem key={language.code} onClick={setLanguageAction(language.code)}>
          <FlagIcon icon={language.icon} />
          {i18n.language[currentLanguage.code][language.code]}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);

LanguageDropdown.propTypes = {
  currentLanguage: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  withProps(props),
)(LanguageDropdown);
