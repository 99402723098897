import { SET_LANGUAGE } from '../ducks/language';

export default ({ getState }) => next => (action) => {
  const result = next(action);

  switch (action.type) {
    case SET_LANGUAGE:
      localStorage.setItem('language', JSON.stringify(getState().get('language')));

      return result;
    default:
      return result;
  }
};
