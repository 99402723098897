export const encodeURL = (url) => {
  const encodedURL = url.replace(/%/g, '((q))');
  // encodeURI(url)

  return encodeURI(encodedURL);
};

export const decodeURL = (url) => {
  const decodedURL = url.replace(/\(\(q\)\)/g, '%');
  // encodeURI(url)

  return decodeURI(decodedURL);
};

export default encodeURL;
