import algoliasearch from 'algoliasearch/lite';

import {
  APPLICATION_ID,
  SEARCH_API_KEY,
  INDEX_NAME,
} from '../constants';

export const searchClient = algoliasearch(APPLICATION_ID, SEARCH_API_KEY, {
  timeout: 4000,
});

const index = searchClient.initIndex(INDEX_NAME);

window.index = index;

export default index;
