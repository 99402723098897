import React from 'react';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-dom';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import i18n from '../../i18n';

import { Input } from './SearchInput.styled';

const mapStateToProps = state => ({
  clientHistory: state.get('clientHistory'),
  language: state.getIn(['language', 'code']),
});

export const props = ({
  currentRefinement,
  clientHistory,
  history,
  location,
  language,
}) => ({
  handleSearchInputChange: (e, refine) => {
    refine(e.currentTarget.value);

    if (e.currentTarget.value.length > 0 && location.pathname !== '/search') {
      history.push('/search');
    }

    if (e.currentTarget.value.length === 0 && location.pathname === '/search') {
      if (clientHistory.size === 0) {
        history.replace('/');

        return;
      }

      history.goBack();
    }
  },
  currentRefinement,
  placeholder: i18n.searchKnowledgeBase[language],
});

const SearchInput = ({ refine, handleSearchInputChange, placeholder }) => (
  <Input
    type="text"
    onChange={event => handleSearchInputChange(event, refine)}
    placeholder={placeholder}
  />
);

SearchInput.propTypes = {
  refine: PropTypes.func.isRequired,
  handleSearchInputChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default compose(
  connect(
    mapStateToProps,
  ),
  withRouter,
  connectSearchBox,
  withProps(props),
)(SearchInput);
